import * as React from "react";
import { stringify } from "query-string";
import { Service } from "../model/Service";
import { ServiceView } from "./ServiceView";

interface State {
  services: Service[];
  nextId: number;
}

export class ServicesView extends React.Component<{}, State> {
  public state: State;

  public set Services(value: Service[]) {
    this.state.services = value;

    this.setState(this.state);
  }

  constructor(props: any) {
    super(props);

    this.state = {
      services: [],
      nextId: 0 };

    this.Services = [];

    this.onReload();
  }

  protected onAddButtonClicked(e: React.MouseEvent) {
    this.state.services.push(Service.fromId("local_id_" + this.state.nextId++));
    this.setState(this.state);
  }

  protected onApplyButtonClicked(e: React.MouseEvent) {
    for (const service of this.state.services) {
      if (service.State === "ADDED") {
        // put service
        fetch("https://" + cfg.apiHost + "/mailer/service", {
          method: "PUT",
          mode: "cors",
          credentials: "include",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(service)
        }).then(
          (result) => {
            if (result.status !== 200) {
              console.log (result.status);
            } else {
              // success, so read new id
              result.body.getReader().read().then(({ done, value }) => {
                if (done) {
                  console.log ("Error, end of stream reached.");
                  return;
                }

                // update service
                const newService = JSON.parse(new TextDecoder("utf-8").decode(value));

                service.Id = newService.id;
                service.ApiKey = newService.apiKey;

                // reset state to unmodified
                service.ResetState();

                // trigger rerender
                this.setState(this.state);
              });
            }
          }
        );
      } else if (service.State === "MODIFIED") {
        // post service
        fetch("https://" + cfg.apiHost + "/mailer/service/" + service.Id, {
          method: "POST",
          mode: "cors",
          credentials: "include",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(service)
        }).then(
          (result) => {
            if (result.status !== 200) {
              console.log (result.status);
            } else {
              // success, so reset state to unmodified
              service.ResetState();

              // trigger rerender
              this.setState(this.state);
            }
          }
        );
      } else if (service.State === "DELETED") {
        // delete service
        fetch("https://" + cfg.apiHost + "/mailer/service/" + service.Id, {
          method: "DELETE",
          mode: "cors",
          credentials: "include",
          headers: {
            "Content-Type": "application/json"
          }
        }).then(
          (result) => {
            if (result.status !== 200) {
              console.log (result.status);
            } else {
              // success, so remove from model
              this.state.services = this.state.services.filter((srv) => {
                return (service.Id !== srv.Id);
              });

              // trigger rerender
              this.setState(this.state);
            }
          }
        );
      }
    }
  }

  protected onRemoveService(serviceId: string) {
    for (const service of this.state.services) {
      if (service.Id === serviceId) {
        service.Remove();
        this.setState(this.state);
        return;
      }
    }
  }

  protected onServiceDidChange() {
    this.forceUpdate();
  }

  protected onReload() {
    fetch("https://" + cfg.apiHost + "/mailer/services", {
      mode: "cors",
      credentials: "include"
    }).then(
      (result) => {
        if (result.status !== 200) {
          if (result.status === 401) {
            console.log("Info, request was unauthorized, redirect to login page");
            window.location.href = "https://" + cfg.apiHost + "/mailer/login?" + stringify({
              redirect: "https://" + window.location.host + "/services"
            });
            return;
          }
          // general error

          console.log ("Error, request was not successful");
          return;
        }

        result.body.getReader().read().then(({ done, value }) => {
          if (done) {
            console.log ("Error, end of stream reached.");
            return;
          }
          const services = JSON.parse(new TextDecoder("utf-8").decode(value));

          if (services && services.map) {
            this.Services = services.map((service: {
              serverName: string;
              serverPort: number;
              userName: string;
              password: string;
              apiKey: string;
              owner: string;
              id: string;
            }) => {
              return Service.fromService(service);
            });
          }
        });
      }
    );
  }

  public isDirty(): boolean {
    for (const service of this.state.services) {
      if (service.State) {
        return true;
      }
    }

    return false;
  }

  public render() {
    let applyButton;
    let clearButton;

    if (this.isDirty()) {
      applyButton = <button onClick={ this.onApplyButtonClicked.bind(this) }>Apply</button>;
      clearButton = <button onClick={ this.onReload.bind(this) }>Clear</button>;
    } else {
      applyButton = <button disabled>Apply</button>;
      clearButton = <button disabled>Clear</button>;
    }

    return this.state.services && (this.state.services.length > 0) && <div>
      <div>
        <div>
          <span>Hostname</span>
          <span>Port</span>
          <span>Username</span>
          <span>Password</span>
          <span>Api key</span>
        </div>
        {
          this.state.services.filter((service) => {
            return service.State !== "DELETED";
          }).map((service) => {
            return <ServiceView
              key={service.Id}
              service={service}
              onRemove={this.onRemoveService.bind(this)}
              onChanged={this.onServiceDidChange.bind(this)}
            />;
          })
        }
      </div>
      <div>
        <button onClick={ this.onAddButtonClicked.bind(this) }>+</button>
        { applyButton }
        { clearButton }
      </div>
    </div>;
  }
}
