export type ServiceState =
  "ADDED" |
  "DELETED" |
  "MODIFIED";

export class Service {
  protected serverName: string;

  public get ServerName(): string {
    return this.serverName;
  }

  public set ServerName(value: string) {
    this.serverName = value;
    if (this.state !== "ADDED") {
      this.state = "MODIFIED";
    }
  }

  protected serverPort: number;

  public get ServerPort(): number {
    return this.serverPort;
  }

  public set ServerPort(value: number) {
    this.serverPort = value;
    if (this.state !== "ADDED") {
      this.state = "MODIFIED";
    }
  }

  protected userName: string;

  public get UserName(): string {
    return this.userName;
  }

  public set UserName(value: string) {
    this.userName = value;
    if (this.state !== "ADDED") {
      this.state = "MODIFIED";
    }
  }

  protected password: string;

  public get Password(): string {
    return this.password;
  }

  public set Password(value: string) {
    this.password = value;
    if (this.state !== "ADDED") {
      this.state = "MODIFIED";
    }
  }

  protected apiKey: string;

  public get ApiKey(): string {
    return this.apiKey;
  }

  public set ApiKey(value: string) {
    this.apiKey = value;
    if (this.state !== "ADDED") {
      this.state = "MODIFIED";
    }
  }

  protected owner: string;

  public get Owner(): string {
    return this.owner;
  }

  protected id: string;

  public get Id(): string {
    return this.id;
  }

  public set Id(value: string) {
    this.id = value;
  }

  protected state: ServiceState;

  public get State(): ServiceState {
    return this.state;
  }

  public ResetState() {
    this.state = undefined;
  }

  public static withDefaults(): Service {
    return new Service();
  }

  public static fromId(id: string): Service {
    const service = new Service();
    service.id = id;
    return service;
  }

  public static fromService(otherService: Service |
    {
      serverName: string;
      serverPort: number;
      userName: string;
      password: string;
      apiKey: string;
      owner: string;
      id: string;
      state?: string;
    }
  ) {
    const service = new Service();

    // make a copy
    if (otherService instanceof Service) {
      service.serverName = otherService.ServerName;
      service.serverPort = otherService.ServerPort;
      service.userName = otherService.UserName;
      service.password = otherService.Password;
      service.apiKey = otherService.ApiKey;
      service.owner = otherService.Owner;
      service.id = otherService.Id;
      service.state = otherService.State ? otherService.state : undefined;
    } else {
      service.serverName = otherService.serverName;
      service.serverPort = otherService.serverPort;
      service.userName = otherService.userName;
      service.password = otherService.password;
      service.apiKey = otherService.apiKey;
      service.owner = otherService.owner;
      service.id = otherService.id;
      service.state = undefined;
    }

    return service;
  }

  constructor() {
    // init with defaults
    this.serverName = "";
    this.serverPort = 0;
    this.userName = "";
    this.password = "";
    this.apiKey = "";
    this.owner = "";
    this.id = "";
    this.state = "ADDED";
  }

  public equals(service: Service): boolean {
    if (!service) {
      return false;
    }

    // just compare everything
    return (
      (this.serverName === service.ServerName) &&
      (this.serverPort === service.ServerPort) &&
      (this.userName === service.UserName) &&
      (this.password === service.Password) &&
      (this.apiKey === service.ApiKey) &&
      (this.owner === service.Owner) &&
      (this.id === service.Id)
    );
  }

  public Remove() {
    this.state = "DELETED";
  }
}
