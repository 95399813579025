import * as React from "react";
import { Service } from "../model/Service";

interface Props {
  service: Service;
  onRemove: (serviceId: string) => void;
  onChanged: () => void;
}

export class ServiceView extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
  }

  protected onRemoveButtonClicked(e: React.MouseEvent) {
    this.props.onRemove(this.props.service.Id);
    this.props.onChanged();
  }

  protected handleServerNameChange(e: any) {
    this.props.service.ServerName = e.target.value;
    this.props.onChanged();
  }

  protected handleServerPortChange(e: any) {
    this.props.service.ServerPort = e.target.value;
    this.props.onChanged();
  }

  protected handleUserNameChange(e: any) {
    this.props.service.UserName = e.target.value;
    this.props.onChanged();
  }

  protected handlePasswordChange(e: any) {
    this.props.service.Password = e.target.value;
    this.props.onChanged();
  }

  protected handleApiKeyChange(e: any) {
    this.props.service.ApiKey = e.target.value;
    this.props.onChanged();
  }

  public render() {
    return <div>
      <input type="text" value={this.props.service.ServerName}
        onChange={ this.handleServerNameChange.bind(this) }/>
      <input type="number" value={ this.props.service.ServerPort !== 0 ? this.props.service.ServerPort : "" }
        onChange={ this.handleServerPortChange.bind(this) }/>
      <input type="text" value={this.props.service.UserName}
        onChange={ this.handleUserNameChange.bind(this) }/>
      <input type="text" value={this.props.service.Password}
        onChange={ this.handlePasswordChange.bind(this) }/>
      <input type="text" value={this.props.service.ApiKey}
        onChange={ this.handleApiKeyChange.bind(this) }/>
      <button onClick={ this.onRemoveButtonClicked.bind(this) }>-</button>
    </div>;
  }
}
